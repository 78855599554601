import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const getNews = (...payload) => {
  let tkk = 'Bearer '+window.localStorage.getItem('app_tt');
  return (dispatch) => {
      return( axios.get(BASE_URL+BASE_VERSION+'news/all', {headers: { Authorization: tkk }, validateStatus: () => true})
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const getDashboard = (...payload) => {
  let tkk = 'Bearer '+window.localStorage.getItem('app_tt');
  return (dispatch) => {
      return( axios.post(BASE_URL+BASE_VERSION+'dashboard', null, {headers: { Authorization: tkk }, validateStatus: () => true})
      .then(function (response) {
        payload = response.data;   
        dispatch({ type: 'DASHBOARD_LOADED', payload })     
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}

export const getSales = (...payload) => {
  let tkk = 'Bearer '+window.localStorage.getItem('app_tt');
  return (dispatch) => {
      return( axios.post(BASE_URL+BASE_VERSION+'sales', null, {headers: { Authorization: tkk }, validateStatus: () => true})
      .then(function (response) {
        payload = response.data;
        dispatch({ type: 'SALES_LOADED', payload })
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      })
    )
  }
}