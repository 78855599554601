import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const getAllRides = (...payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    // api/event/all
    // console.log(BASE_URL + BASE_VERSION + "event/" + payload.slug);
    // /all?provinces=
    return axios
      .get(BASE_URL + BASE_VERSION + "rides", {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};
