import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const login = (payload) => {
  return (dispatch) => {
    var payloadString = btoa(JSON.stringify({ email: payload.email, password: payload.password }));
    return axios
      .post(BASE_URL + BASE_VERSION + "auth/signin", {
        payload: payloadString,
      })
      .then(function (response) {
        payload = response.data;
        if (payload.code === 200) {
          if (payload.data.id === 1 || payload.data.id === 2) {
            dispatch({ type: "SET_LOGIN", payload });
          } else {
            payload = { code: 401, message: "Invalid credentials, please try again." };
          }
        } else {
          dispatch({ type: "SET_LOGOUT", payload });
        }
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        return payload;
      });
  };
};

export const getUserAll = (...payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "user/all", {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const checkLogin = (...payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "user/me", {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        if (payload.code === 200) {
          if (payload.data.id === 1 || payload.data.id === 2) {
            payload.data.accessToken = window.localStorage.getItem("app_tt");
            // payload.token = { access_token: window.localStorage.getItem("app_tt") };
            dispatch({ type: "SET_LOGIN", payload });
          } else {
            dispatch({ type: "SET_LOGOUT", payload });
            payload = { code: 401, message: "Invalid credentials, please try again." };
          }
        } else {
          dispatch({ type: "SET_LOGOUT", payload });
          payload = { code: 401, message: "Invalid credentials, please try again." };
        }
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        dispatch({ type: "SET_LOGOUT", payload });
        return payload;
      });
  };
};

export const forceLogout = (...payload) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOGOUT", payload });
    payload = { code: 200, message: "Success Logout" };
    return payload;
  };
};
