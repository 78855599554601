import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout, getUserAll } from "../../../redux/actions/users";

import { withRouter } from "react-router-dom";
// import { format } from "date-fns";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: [],
      err: {},
    };

    // this.onLogout = this.onLogout.bind(this);
    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  //   onLogout(params) {
  //     console.log("onLogout");
  //     if (this.props.forceLogout().status === 200) {
  //       this.props.history.push("/");
  //     }
  //   }

  //   toggleMenu(params) {}

  componentDidMount() {
    console.log("header mounted");
    this.props
      .getUserAll()
      .then((res) => {
        console.log(res);
        console.log("getUserAll");
        if (res.code === 200) {
          this.setState({
            loaded: true,
            data: res.data,
          });
        } else {
          alert("Something Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (!this.state.loaded) {
      return <div>Please wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Users</span>
          <div
            className="bg-blue-700 cursor-pointer p-2 text-sm rounded-md select-none"
            onClick={() => {
              console.log("add news!");
            }}
          >
            Add User
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {this.state.data.map((user) => {
            return (
              <div key={user.id} className="bg-zinc-100 p-2 rounded-lg flex flex-row items-center gap-2 shadow-lg">
                <div className="flex flex-0 p-2 items-center justify-center content-center">
                  {user.imageurl !== null ? (
                    <div className="avatar" style={{ backgroundImage: "url(" + user.imageurl + ")" }}></div>
                  ) : (
                    <div className="avatar">{user.fullname[0].toUpperCase()}</div>
                  )}
                </div>
                <div className="flex flex-col flex-1">
                  <span className="text-base">
                    {user.fullname} - {user.username}
                  </span>
                  <span className="text-sm">{user.email}</span>
                  <span className="text-xs">{/* Joined at : {format(new Date(user.created_at), "yyyy p")} */}</span>
                </div>
                <div className="flex-0">
                  <div className="text-sm p-2 bg-blue-800 text-white rounded cursor-pointer hover:bg-blue-900 min-w-16 text-center select-none">Detail</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUserAll: (data) => dispatch(getUserAll(data)),
    forceLogout: (data) => dispatch(forceLogout(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User));
