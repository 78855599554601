import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../../../redux/actions/users";

import { withRouter } from "react-router-dom";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_type: 0,
      mobileMenu: false,
      logoIcon: "",
      err: {},
    };
    this.onLogout = this.onLogout.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  onLogout(params) {
    console.log("onLogout");
    if (this.props.forceLogout().status === 200) {
      this.props.history.push("/");
    }
  }

  toggleMenu(params) {}

  componentDidMount() {
    console.log("header mounted");
  }

  render() {
    return (
      <div className="panel home-panel">
        <h1>Reports</h1>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forceLogout: (data) => dispatch(forceLogout(data)),
    //logout: data => dispatch(logout(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports));
