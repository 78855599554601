import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const getNewsCategories = (...payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "news/category/all", {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const getNews = (...payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "news/admin/all", {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const getNewsDetail = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "news/admin/" + payload.slug, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const createNews = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    const data = new FormData();

    if (payload.image) {
      data.append("files", payload.image);
    }

    data.append("slug", payload.slug);
    data.append("title", payload.title);
    if (payload.caption) {
      data.append("caption", payload.caption);
    }
    data.append("content", payload.content);
    data.append("idcategory", payload.idcategory);
    data.append("is_active", payload.is_active);

    return axios
      .post(BASE_URL + BASE_VERSION + "news/admin", data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const updateNews = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    const data = new FormData();

    if (payload.image) {
      data.append("files", payload.image);
    }

    data.append("title", payload.title);
    data.append("caption", payload.caption);
    data.append("content", payload.content);
    data.append("idcategory", payload.idcategory);
    data.append("is_active", payload.is_active);

    return axios
      .put(BASE_URL + BASE_VERSION + "news/admin/" + payload.slug, data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};
