import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../../../../redux/actions/users";

import { withRouter } from "react-router-dom";

class EventCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      err: {},
    };

    // this.onLogout = this.onLogout.bind(this);
    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  // onLogout(params) {
  //   console.log("onLogout");
  //   if (this.props.forceLogout().status === 200) {
  //     this.props.history.push("/");
  //   }
  // }

  // toggleMenu(params) {}

  componentDidMount() {
    console.log("event categories mounted");
  }

  render() {
    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Events Categories</span>
          <div
            className="bg-blue-700 cursor-pointer p-2 text-sm rounded-md select-none"
            onClick={() => {
              console.log("add news!");
            }}
          >
            Add Category
          </div>
        </div>
        <div>

        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forceLogout: (data) => dispatch(forceLogout(data)),
    //logout: data => dispatch(logout(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventCategories));
