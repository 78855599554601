import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";

// import { Carousel } from "react-responsive-carousel";
// import { ReactSortable } from "react-sortablejs";

import SlidersHome from "./slidersHome";
import SlidersGarage from "./slidersGarage";
import SlidersEvent from "./slidersEvent";
import SlidersMarketplace from "./slidersMarketplace";

class Sliders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: null,
      // sliderHome: [],
      // sliderEvent: [],
      // sliderGarage: [],
      // sliderMarketplace: [],
    };

    // this.handleChange = this.handleChange.bind(this);
  }

  // handleChange() {
  //   console.log("change tab");
  // }

  render() {
    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Sliders</span>
        </div>
        <div className="flex gap-3 flex-col">
          <SlidersHome />
          <SlidersMarketplace />
          <SlidersEvent />
          <SlidersGarage />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // forceLogout: (data) => dispatch(forceLogout(data)),
    // getSliders: (data) => dispatch(getSliders(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sliders));
