import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../../../../redux/actions/users";
import { getProductCategories } from "../../../../redux/actions/products";

import { withRouter } from "react-router-dom";

class ProductCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: [],
      err: {},
    };

    // this.onLogout = this.onLogout.bind(this);
    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  // onLogout(params) {
  //   console.log("onLogout");
  //   if (this.props.forceLogout().status === 200) {
  //     this.props.history.push("/");
  //   }
  // }
  // toggleMenu(params) {}

  componentDidMount() {
    console.log("header mounted");
    this.props
      .getProductCategories()
      .then((res) => {
        console.log(res);
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Product Categories</span>
          <div
            className="bg-blue-700 cursor-pointer p-2 text-sm rounded-md select-none"
            onClick={() => {
              console.log("add news!");
            }}
          >
            Add Items
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {this.state.data.map((item) => {
            return (
              <div
                key={item.id}
                className="bg-zinc-100 p-2 flex flex-row justify-between align-middle items-center rounded-lg"
              >
                <div>{item.name}</div>
                <div className="text-sm p-2 bg-blue-800 text-white rounded cursor-pointer hover:bg-blue-900 min-w-16 text-center">
                  Detail
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forceLogout: (data) => dispatch(forceLogout(data)),
    getProductCategories: (data) => dispatch(getProductCategories(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductCategories));
