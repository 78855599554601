import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import {
  getSliders,
  changeStatus,
  addSlider,
  removeSlider,
  reorderSlider,
} from "../../../redux/actions/sliders";

import { Carousel } from "react-responsive-carousel";
import { ReactSortable } from "react-sortablejs";

import Files from "react-files";

class SliderMarketplace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaded: false,
      image: undefined,
      imageUrl: undefined,
      err: null,
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileError = this.handleFileError.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
  }

  handleStatus = (is_active, id) => {
    this.props
      .changeStatus({ slug: "marketplace", id: id, is_active: is_active })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch((err) => {
        alert("Something Error");
      });
  };

  handleSubmit = () => {
    console.log(this.state.image);
    console.log("SUBMIT!");
    this.props
      .addSlider({ slug: "marketplace", image: this.state.image })
      .then((res) => {
        console.log(res.data);
        if (res.code === 200) {
          window.location.reload(false);
          // alert("Upload Success");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleFileChange = (files) => {
    this.setState({
      image: files[0],
      imageUrl: files[0].preview.url,
    });
  };

  handleFileError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  handleDelete(e) {
    // console.log(e.target.idx);
    // console.log(e.target.attribute("idx"));
    // console.log(e.target.name);
    // console.log(e.target.value);
    console.log("delete slide : ");
    console.log(e.target.getAttribute("data-idx"));
    // 'marketplace'

    let confirm = window.confirm("Are you sure to permanently delete?");
    if (confirm) {
      // alert("Permanently Delete!");
      this.props
        .removeSlider({ slug: "marketplace", id: parseInt(e.target.getAttribute("data-idx")) })
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            // alert("Delete Success");
            window.location.reload(false);
          } else {
            alert("Something Error");
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Something Error");
        });
    }
  }

  handleChange() {
    console.log("change tab");
  }

  componentDidMount() {
    this.props
      .getSliders({ slug: "marketplace" })
      .then((res) => {
        console.log(res);
        this.setState({
          data: res.data,
          loaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (this.state.loaded !== true) {
      return <div>Loading...</div>;
    }

    return (
      <div className="bg-zinc-100 p-2 rounded-lg flex flex-col gap-2 shadow-lg">
        <span className="text-lg font-semibold self-center">Marketplace</span>
        <div className="flex flex-row gap-2">
          <div className="w-1/2 select-none">
            <Carousel
              width="100%"
              infiniteLoop
              emulateTouch={false}
              showThumbs={false}
              className="slider-ratio"
            >
              {this.state.data.map((item) => {
                return (
                  <div
                    key={item.updated_at}
                    className="slider-item"
                    style={{ backgroundImage: "url(" + item.imageurl + ")" }}
                  ></div>
                  // <img
                  //   className="slider-item"
                  //   alt={item.updated_at}
                  //   key={item.updated_at}
                  //   src={item.imageurl}
                  // />
                );
              })}
            </Carousel>
          </div>
          <div className="w-1/2 flex flex-col">
            <div className="flex-1 flex flex-col gap-2">
              <ReactSortable
                className="grid-cols-3 grid gap-2"
                // className="flex-0 flex flex-row gap-2"
                list={this.state.data}
                setList={(newState) =>
                  this.setState({ data: newState }, () => {
                    let dataOrdered = newState.map((item, index) => {
                      return { id: item.id, index: index };
                    });
                    this.props
                      .reorderSlider({
                        slug: "marketplace",
                        data: JSON.stringify(dataOrdered),
                      })
                      .then((res) => {
                        console.log(res);
                        console.log("reorder success");
                      })
                      .catch((err) => {
                        console.log(err);
                        console.log("reorder error");
                      });
                  })
                }
              >
                {this.state.data.map((item) => (
                  <div
                    key={"marketplace-" + item.id}
                    className="select-none cursor-move w-full aspect-2 overflow-hidden relative flex justify-center align-middle items-center slider-ratio"
                  >
                    <img src={item.imageurl} alt={item.updated_at} />
                    <div
                      data-idx={item.id}
                      className="absolute top-1 right-1 cursor-pointer text-xs text-white"
                      onClick={this.handleDelete}
                    >
                      Delete
                    </div>
                    <div
                      data-idx={item.id}
                      className="absolute bottom-1 left-1 cursor-pointer text-xs text-white"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleStatus(item.is_active, item.id);
                      }}
                    >
                      {item.is_active ? "Published" : "Draft"}
                    </div>
                  </div>
                ))}
              </ReactSortable>
              <Files
                className="files-dropzone"
                onChange={this.handleFileChange}
                onError={this.handleFileError}
                accepts={["image/*"]}
                // accepts={["image/png", ".pdf", "audio/*"]}
                multiple={false}
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <div
                  className="w-1/4 bg-zinc-400"
                  style={{
                    display: "flex",
                    fontSize: "12px",
                    cursor: "pointer",
                    alignItems: "center",
                    aspectRatio: "1/0.52",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "calc(33.3% - 5px)",
                    backgroundColor: "#dcdcdc",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundImage: "url(" + this.state.imageUrl + ")",
                  }}
                >
                  {!this.state.imageUrl ? "Add Image" : ""}
                </div>
              </Files>
              {this.state.imageUrl ? (
                <div
                  className="cursor-pointer text-xs text-white bg-green-600 p-2 rounded w-fit"
                  onClick={this.handleSubmit}
                >
                  Upload
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <div className="flex flex-row gap-2 justify-end">
              <div className="p-2 bg-green-700 cursor-pointer hover:bg-green-800 text-white rounded w-fit text-sm">
                Preview
              </div>
              <div className="p-2 bg-orange-700 cursor-pointer hover:bg-orange-800 text-white rounded w-fit text-sm">
                Publish
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addSlider: (data) => dispatch(addSlider(data)),
    getSliders: (data) => dispatch(getSliders(data)),
    changeStatus: (data) => dispatch(changeStatus(data)),
    removeSlider: (data) => dispatch(removeSlider(data)),
    reorderSlider: (data) => dispatch(reorderSlider(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SliderMarketplace));
