import React, { Component } from "react";
import { connect } from "react-redux";

// import { forceLogout } from "../../../redux/actions/users";
import { getEvents, getEventDetail } from "../../../redux/actions/events";

import { withRouter } from "react-router-dom";

import { format, formatDistanceToNow } from "date-fns";

class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: null,
      comments: null,
      err: {},
    };

    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  // toggleMenu(params) {}

  componentDidMount() {
    // console.log("events mounted");
    this.props
      .getEventDetail({ slug: this.props.match.params.slug })
      .then((res) => {
        console.log(res);
        this.setState(
          {
            data: res.data,
            comments: res.comments,
            loaded: true,
          },
          () => {
            console.log(this.state.data);
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (!this.state.loaded) {
      return <div>Plase wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Event Detail</span>
          <div
            className="bg-blue-700 cursor-pointer p-2 text-sm rounded-md select-none"
            onClick={() => {
              console.log("Edit Event");
              this.props.history.push("/dashboard/event/edit/" + this.state.data.slug);
            }}
          >
            Edit Event
          </div>
        </div>

        <div className="flex-row w-full flex-wrap mb-6">
          <div key={this.state.data.id} className="container bg-gray-50 p-2 rounded-md shadow">
            {this.state.data.imageurl !== null ? (
              <img
                src={this.state.data.imageurl}
                alt={"thumbnail-" + this.state.data.title}
                className="cursor-pointer news-item-thumbnail w-1/2 hover:grayscale-0 "
              />
            ) : (
              ""
            )}
            <div className="text-sm">{format(new Date(this.state.data.start_date), "dd MMM yyyy | p")}</div>
            <div className="font-bold cursor-pointer">{this.state.data.title}</div>
            <div className="text-base">{this.state.data.caption}</div>
            <div className="text-base">{this.state.data.content}</div>
            <div className="text-base">
              Start : {format(new Date(this.state.data.start_date), "dd MMM yyyy | p")}
            </div>
            <div className="text-base">
              Finish : {format(new Date(this.state.data.start_date), "dd MMM yyyy | p")}
            </div>
            <div className="text-base">Venue : {this.state.data.venue}</div>
            <div className="text-base capitalize">
              {this.state.data.province.name}, {this.state.data.city.name}
            </div>
            {this.state.data.is_ticket ? (
              <div className="bg-blue-500 text-xs text-white w-fit p-2 align-middle rounded mt-2 select-none">
                Ticket Available
              </div>
            ) : (
              <div className="bg-red-500 text-xs text-white w-fit p-2 align-middle rounded mt-2 select-none">
                No Ticket
              </div>
            )}
          </div>

          {!this.state.data.disable_comment ? (
            <div className="p-2 bg-zinc-200 mt-2 rounded gap-2 flex flex-col">
              <span>Comments</span>
              {this.state.comments.length !== 0 ? (
                <>
                  {this.state.comments?.map((item) => {
                    return (
                      <div key={item.id} className="flex flex-row bg-white p-2 rounded gap-2">
                        <div className="text-xs justify-center">
                          {item.user.imageurl ? (
                            <img className="w-14 rounded-full" src={item.user.imageurl} alt="user-comment-avatar" />
                          ) : (
                            "No Ava"
                          )}
                        </div>
                        <div className="flex flex-col justify-center gap-1">
                          <span className="text-xs font-bold">{item.user.fullname}</span>
                          <span className="text-xs">{item.content}</span>
                          <span className="text-xs">
                            {formatDistanceToNow(new Date(item.created_at), { addSuffix: true })}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <span className="text-sm">No Comments found</span>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getEvents: (data) => dispatch(getEvents(data)),
    getEventDetail: (data) => dispatch(getEventDetail(data)),
    // forceLogout: (data) => dispatch(forceLogout(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventDetails));
