import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../../../redux/actions/users";
import { getStores } from "../../../redux/actions/stores";

import { withRouter } from "react-router-dom";

class Shops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: [],
      err: {},
    };

    // this.onLogout = this.onLogout.bind(this);
    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  // onLogout(params) {
  //   console.log("onLogout");
  //   if (this.props.forceLogout().status === 200) {
  //     this.props.history.push("/");
  //   }
  // }

  // toggleMenu(params) {}

  componentDidMount() {
    this.props
      .getStores()
      .then((res) => {
        console.log(res);
        this.setState({
          data: res.data,
          loaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("header mounted");
  }

  render() {
    if (!this.state.loaded) {
      return <div>Plase wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Stores</span>
          <div
            className="bg-blue-700 cursor-pointer p-2 text-sm rounded-md select-none"
            onClick={() => {
              console.log("add news!");
            }}
          >
            Add Items
          </div>
        </div>
        <div className="flex-row w-full flex-wrap grid-cols-4 grid gap-4 mb-6">
          {this.state.data?.map((item) => {
            return (
              <div
                key={item.id}
                className="flex flex-col align-middle items-center bg-gray-50 p-3 rounded-md shadow"
              >
                <div
                  className="rounded-full w-16 h-16 mb-2"
                  style={{ backgroundImage: "url(" + item.imageurl + ")", backgroundSize: "cover" }}
                ></div>
                <span className="font-bold my-1">{item.name}</span>
                <span className="text-sm">{item.description}</span>
                <span className="text-sm">{item.address}</span>
                <span className="text-sm capitalize">{item.province.name}</span>
                <span className="text-sm capitalize">{item.city.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getStores: (data) => dispatch(getStores(data)),
    forceLogout: (data) => dispatch(forceLogout(data)),
    //logout: data => dispatch(logout(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shops));
