import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../../redux/actions/users";
import { formatMoney } from "../../utils/format";

import "../../styles/table.css";

import { withRouter } from "react-router-dom";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_type: 0,
      mobileMenu: false,
      logoIcon: "",
      err: {},
    };
    //this.onLogout = this.onLogout.bind(this);
    //this.toggleMenu = this.toggleMenu.bind(this);
  }

  // onLogout(params) {
  //     if(this.props.forceLogout().status === 200){
  //         this.props.history.push("/");
  //     }
  // }

  // toggleMenu(params){

  // }

  componentDidMount() {
    //console.log(this.props.dashboardData);
  }

  render() {
    // let userCount = this.props.dashboardData['user'].length;
    // let artistCount = this.props.dashboardData['artist'].length;
    // let contentCount = this.props.dashboardData['content'].length;
    // let contentTransCount = this.props.dashboardData['transcontent'].length;
    // let supportCount = this.props.dashboardData['support'].length;
    // let transCount = this.props.dashboardData['transaction'].length;
    // let bundleCount = this.props.dashboardData['bundle'].length;
    // let sumTransaction = 0;
    // let totalProfit = 0;
    // for (let t = 0;t < transCount;t++){
    //     sumTransaction += this.props.dashboardData['transaction'][t].total_amount;
    // }
    // totalProfit = parseInt(sumTransaction * 0.1);

    return (
      <div className="panel home-panel">
        {/* <h1>Home</h1> */}
        <div className="grid grid-cols-4 gap-4">
          <div className="card card-50">
            <span className="card-title">Total Sales</span>
            {/* <span className="card-money">{this.props.formatMoney(sumTransaction)}</span> */}
          </div>
          <div className="card card-50">
            <span className="card-title">Total Profit</span>
            {/* <span className="card-money">{this.props.formatMoney(totalProfit)}</span> */}
          </div>
          <div className="card card-33">
            <span className="card-title">Transactions</span>
            {/* <span className="card-number">{transCount}</span> */}
          </div>
          <div className="card card-33">
            <span className="card-title">Contents Sold From</span>
            {/* <span className="card-number">{contentTransCount}/{contentCount}</span> */}
          </div>
          <div className="card card-33">
            <span className="card-title">Supports</span>
            {/* <span className="card-number">{supportCount}</span> */}
          </div>
          <div className="card card-33">
            <span className="card-title">Users</span>
            {/* <span className="card-number">{userCount}</span> */}
          </div>
          <div className="card card-33">
            <span className="card-title">Artists</span>
            {/* <span className="card-number">{artistCount}</span> */}
          </div>
          <div className="card card-33">
            <span className="card-title">Bundles</span>
            {/* <span className="card-number">{bundleCount}</span> */}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forceLogout: (data) => dispatch(forceLogout(data)),
    formatMoney,
    //logout: data => dispatch(logout(data))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
