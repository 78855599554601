import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../../../redux/actions/users";
import { getAllRides } from "../../../redux/actions/rides";

import { withRouter } from "react-router-dom";

class Rides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: [],
      err: {},
    };
  }

  componentDidMount() {
    console.log("events mounted");
    this.props
      .getAllRides({ slug: "" })
      .then((res) => {
        console.log(res);
        this.setState({
          data: res.data,
          loaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (!this.state.loaded) {
      return <div>Please wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Rides</span>
          <div
            className="bg-blue-700 cursor-pointer p-2 text-sm rounded-md select-none"
            onClick={() => {
              console.log("add news!");
            }}
          >
            Add Ride
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {this.state.data?.map((item) => {
            return (
              <div
                key={item._id}
                className="flex flex-col bg-zinc-50 gap-2 cursor-pointer p-2 pt-0 pl-0 pr-0 text-sm rounded-md shadow-md"
              >
                <div className="flex flex-row gap-2 cursor-pointer p-2 text-sm rounded-md shadow-md items-center align-middle bg-zinc-100">
                  {item.image_url !== undefined ? (
                    <div className="flex flex-0 p-2 items-center justify-center content-center">
                      <div
                        className="w-60 aspect-2"
                        style={{
                          backgroundImage: "url(" + item.image_url + ")",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="flex flex-col flex-1">
                    <span className="text-md font-semibold">{item.title}</span>
                    <span className="text-md font-light">{item.description}</span>
                  </div>
                  <div className="flex-0 flex flex-row gap-2 p-3">
                    <div className="text-sm p-2 bg-orange-700 text-white rounded cursor-pointer hover:bg-orange-900 min-w-16 text-center">
                      Edit
                    </div>
                    <div className="text-sm p-2 bg-blue-800 text-white rounded cursor-pointer hover:bg-blue-900 min-w-16 text-center">
                      Preview
                    </div>
                  </div>
                </div>

                <div className="p-2 flex flex-col gap-1">
                  <span className="text-md font-semibold">Detail Rides</span>
                  <span>Avg. Speed : {item.avg_speed} Kmh</span>
                  <span>Max. Speed : {item.max_speed} Kmh</span>
                  <span>Range : {item.range} Km</span>
                  <span>Finish Point : {item.origin_point[0] + ", " + item.origin_point[1]}</span>
                  <span>Finish Point : {item.destination_point[0] + ", " + item.destination_point[1]}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forceLogout: (data) => dispatch(forceLogout(data)),
    getAllRides: (data) => dispatch(getAllRides(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rides));
