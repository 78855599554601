import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const createEvent = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    const data = new FormData();

    if (payload.image) {
      data.append("files", payload.image);
    }

    data.append("idcategory", payload.idcategory);
    data.append("slug", payload.slug);
    data.append("title", payload.title);
    if (payload.caption) {
      data.append("caption", payload.caption);
    }
    data.append("content", payload.content);
    data.append("venue", payload.venue);
    data.append("idcity", payload.idcity);
    data.append("idprovince", payload.idprovince);
    data.append("disable_comment", payload.disable_comment);
    data.append("is_ticket", payload.is_ticket);
    data.append("is_active", payload.is_active);

    return axios
      .post(BASE_URL + BASE_VERSION + "event/admin", data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const updateEvent = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    const data = new FormData();

    if (payload.image) {
      data.append("files", payload.image);
    }

    data.append("title", payload.title);
    data.append("venue", payload.venue);
    data.append("latlon", payload.latlon);
    data.append("caption", payload.caption);
    data.append("content", payload.content);
    data.append("idcity", payload.idcity);
    data.append("idprovince", payload.idprovince);
    data.append("idcategory", payload.idcategory);
    data.append("is_ticket", payload.is_ticket);
    data.append("is_active", payload.is_active);
    data.append("disable_comment", payload.disable_comment);

    return axios
      .put(BASE_URL + BASE_VERSION + "event/admin/" + payload.id, data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const getEvents = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "event/admin/all?provinces=" + payload.slug, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const getEventDetail = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "event/admin/" + payload.slug, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};
