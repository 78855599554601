import React, { Component } from "react";
import { connect } from "react-redux";
import {
  //   useLocation,
  //   useHistory,
  withRouter,
  Redirect,
  Route,
  Switch,
  // Link,
  // NavLink
} from "react-router-dom";

import "../styles/login.css";

// import { login } from '../redux/actions/users'

import { getDashboard, getSales } from "../redux/actions/dashboards";

import SideMenu from "../components/sideMenu";

import Home from "./dashboard/index";
import Analytic from "./dashboard/analytics/analytics";
import Setting from "./dashboard/settings/settings";
import Reports from "./dashboard/reports/reports";

// import Content from "./dashboard/content";
// import Withdraw from "./dashboard/withdraw";
// import Promotions from "./dashboard/promotions";

import User from "./dashboard/users/index";

import News from "./dashboard/news/index";
import NewsAdd from "./dashboard/news/add";
import NewsEdit from "./dashboard/news/edit";

import Events from "./dashboard/events/index";
import EventAdd from "./dashboard/events/add";
import EventEdit from "./dashboard/events/edit";
import EventDetails from "./dashboard/events/detail";
import EventCategories from "./dashboard/events/categories/index";

import Clubs from "./dashboard/clubs/index";

import Stores from "./dashboard/stores/index";

import Products from "./dashboard/products/products";
import ProductCategories from "./dashboard/products/categories/product-categories";

import Sliders from "./dashboard/sliders/sliders";
import SlidersHome from "./dashboard/sliders/slidersHome";
import SlidersEvent from "./dashboard/sliders/slidersEvent";
import SlidersMarketplace from "./dashboard/sliders/slidersMarketplace";
import SlidersGarage from "./dashboard/sliders/slidersGarage";

import Rides from "./dashboard/rides/rides";

import Challenges from "./dashboard/challenges/challenges";

import Routes from "./dashboard/routes/routes";

import Provinces from "./dashboard/geo/provinces";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false, //true,
      data: null,
      sales: null,
      err: "",
    };

    // this.refreshDashboard = this.refreshDashboard.bind(this);
    // this.refreshSales = this.refreshSales.bind(this);
    // this.onLogout = this.onLogout.bind(this);
  }

  // refreshDashboard() {
  //  console.log("Refresh Dashboard");
  // }

  // refreshSales() {
  //  console.log("Refresh Sales");
  // }

  //   componentDidMount() {
  // const location = useLocation();
  // console.log(location);
  // this.props.getDashboard().then((res) => {
  //     this.setState({data:res.data, isLoading:false})
  // })
  // this.props.getSales().then((res) => {
  //     this.setState({sales:res.data})
  // })
  //   }

  render() {
    const url = this.props.match.url;
    const { isLoading } = this.state;

    return (
      <div className="dashboard-container fullheight flex">
        {/* {this.props.isLogin === true ? <Redirect to="/dashboard/home"></Redirect> : ""} */}

        <div className="h-full w-72 bg-blue-900 overflow-y-scroll sidemenu-container">
          <SideMenu></SideMenu>
        </div>
        {isLoading !== true ? (
          <div className="w-full p-3 overflow-y-scroll">
            <Switch>
              <Route path={`${url}/home`}>
                <Home refresh={this.refreshDashboard} />
              </Route>

              <Route path={`${url}/news/edit/:slug`}>
                <NewsEdit />
              </Route>
              <Route path={`${url}/news/add`}>
                <NewsAdd />
              </Route>
              <Route path={`${url}/news`}>
                <News />
              </Route>

              <Route path={`${url}/event/edit/:slug`}>
                <EventEdit />
                {/* Edit Event */}
              </Route>
              <Route path={`${url}/event/add`}>
                <EventAdd />
                {/* Add Event */}
              </Route>
              <Route path={`${url}/event/:slug`}>
                <EventDetails />
              </Route>
              <Route path={`${url}/events`}>
                <Events />
              </Route>

              <Route path={`${url}/event-categories`}>
                <EventCategories />
              </Route>

              <Route path={`${url}/sliders/home`}>
                <SlidersHome />
              </Route>
              <Route path={`${url}/sliders/event`}>
                <SlidersEvent />
              </Route>
              <Route path={`${url}/sliders/marketplace`}>
                <SlidersMarketplace />
              </Route>
              <Route path={`${url}/sliders/garage`}>
                <SlidersGarage />
              </Route>
              <Route path={`${url}/sliders`}>
                <Sliders />
              </Route>

              <Route path={`${url}/users`}>
                <User />
              </Route>

              <Route path={`${url}/stores`}>
                <Stores />
              </Route>
              <Route path={`${url}/products`}>
                <Products />
              </Route>
              <Route path={`${url}/product-categories`}>
                <ProductCategories />
              </Route>

              <Route path={`${url}/clubs`}>
                <Clubs />
              </Route>

              <Route path={`${url}/rides`}>
                <Rides />
              </Route>
              <Route path={`${url}/routes`}>
                <Routes />
              </Route>
              <Route path={`${url}/challenges`}>
                <Challenges />
              </Route>
              {/* <Route path={`${url}/promotions`}>
                <Promotions />
              </Route> */}

              <Route path={`${url}/analytics`}>
                <Analytic />
              </Route>

              <Route path={`${url}/reports`}>
                <Reports />
              </Route>

              <Route path={`${url}/provinces`}>
                <Provinces />
              </Route>

              <Route path={`${url}/settings`}>
                <Setting />
              </Route>

              <Route exact path={`${url}`}>
                <Redirect to={`${url}/home`} />
              </Route>
            </Switch>
          </div>
        ) : (
          "Loading"
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getDashboard: (data) => dispatch(getDashboard(data)),
    getSales: (data) => dispatch(getSales(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
