import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const reorderSlider = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    const data = new FormData();
    data.append("data", payload.data);
    return axios
      .put(BASE_URL + BASE_VERSION + "slider/admin/reorder/" + payload.slug, data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const changeStatus = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    const data = new FormData();
    data.append("is_active", payload.is_active);
    return axios
      .put(BASE_URL + BASE_VERSION + "slider/admin/" + payload.slug + "/" + payload.id, data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const removeSlider = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    console.log(BASE_URL + BASE_VERSION + "slider/admin/" + payload.slug);

    return axios
      .delete(BASE_URL + BASE_VERSION + "slider/admin/" + payload.slug + "/" + payload.id, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const addSlider = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    console.log(BASE_URL + BASE_VERSION + "slider/admin/" + payload.slug);

    const data = new FormData();
    data.append("files", payload.image);

    return axios
      .post(BASE_URL + BASE_VERSION + "slider/admin/" + payload.slug, data, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const getSliders = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    console.log(BASE_URL + BASE_VERSION + "slider/admin/" + payload.slug);
    return axios
      .get(BASE_URL + BASE_VERSION + "slider/admin/" + payload.slug, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

export const updateSlider = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    console.log(BASE_URL + BASE_VERSION + "slider/admin/" + payload.slug);
    return axios
      .put(BASE_URL + BASE_VERSION + "slider/admin/" + payload.slug, {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};

// api/store/get-all
