import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const getStores = (payload) => {
  let tkk = "Bearer " + window.localStorage.getItem("app_tt");
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "store/get-all", {
        headers: { Authorization: tkk },
        validateStatus: () => true,
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload.error = error;
        console.log(error);
        return payload;
      });
  };
};