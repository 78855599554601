/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";

// import { forceLogout } from "../../../redux/actions/users";
// import { getNews } from "../../../redux/actions/dashboards";
import { getNews } from "../../../redux/actions/news";

// NavLink,
import { withRouter, Link } from "react-router-dom";

import { format } from "date-fns";

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: [],
      err: {},
    };

    // this.onLogout = this.onLogout.bind(this);
    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  // onLogout(params) {
  //   console.log("onLogout");
  //   if (this.props.forceLogout().status === 200) {
  //     this.props.history.push("/");
  //   }
  // }

  // toggleMenu(params) {}

  componentDidMount() {
    console.log("news mounted");
    this.props
      .getNews()
      .then((res) => {
        console.log(res);
        console.log("getNews");
        if (res.code === 200) {
          this.setState({
            loaded: true,
            data: res.data,
          });
        } else {
          alert("Something Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const url = this.props.match.url;

    if (!this.state.loaded) {
      return <div>Loading...</div>;
    }
    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">News</span>
          <Link to={url + "/add"}>
            {/* className="flex justify-end absolute top-2 right-2" */}
            <div
              className="bg-blue-700 cursor-pointer p-2 text-sm rounded-md select-none"
              // onClick={() => {
              //   console.log("add news!");
              // }}
            >
              Add News
            </div>
          </Link>
        </div>
        <div className="news-list flex flex-col gap-2">
          {this.state.data?.map((news) => {
            return (
              <div key={news.id} className="container bg-zinc-50 p-3 rounded-md shadow flex flex-col gap-2 relative">
                {/* <div className="font-serif">{news.slug}</div> */}
                <img src={news.imageurl} alt={"thumbnail-" + news.title} className="news-item-thumbnail w-1/2" />
                <div className="text-lg font-semibold">{news.title}</div>
                <div className="text-sm font-semibold">Created : {format(new Date(news.created_at), "dd MMM yyy | p")}</div>
                {/* <div className="text-sm">{news.caption}</div> */}
                <div className="text-sm whitespace-break-spaces">{news.content}</div>
                <div className="text-xs font-bold p-1 px-2 bg-slate-400 w-fit rounded text-white select-none">{news.category.title}</div>
                <div className="text-sm">Status : {news.is_active ? "Active" : "Draft"}</div>
                <Link to={url + "/edit/" + news.slug} className="flex justify-end absolute top-2 right-2">
                  <div className="self-end text-sm w-fit p-2 bg-lime-800 text-white rounded cursor-pointer hover:bg-lime-900 min-w-16 text-center">Edit</div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getNews: (data) => dispatch(getNews(data)),
    // forceLogout: (data) => dispatch(forceLogout(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(News));
