import React, { Component } from "react";
import { connect } from "react-redux";

import { forceLogout } from "../../../redux/actions/users";
import { getEvents } from "../../../redux/actions/events";

import { withRouter } from "react-router-dom";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: [],
      err: {},
    };

    // this.onLogout = this.onLogout.bind(this);
    // this.toggleMenu = this.toggleMenu.bind(this);
  }

  // onLogout(params) {
  //   console.log("onLogout");
  //   if (this.props.forceLogout().status === 200) {
  //     this.props.history.push("/");
  //   }
  // }

  // toggleMenu(params) {}

  componentDidMount() {
    console.log("events mounted");
    this.props
      .getEvents({ slug: "" })
      .then((res) => {
        console.log(res);
        this.setState({
          // data: res.data,
          loaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (!this.state.loaded) {
      return <div>Plase wait...</div>;
    }

    return (
      <div className="panel home-panel">
        <div className="flex justify-between items-center p-3 bg-blue-900 text-white rounded-md mb-2">
          <span className="text-lg font-semibold">Routes</span>
          <div
            className="bg-blue-700 cursor-pointer p-2 text-sm rounded-md select-none"
            onClick={() => {
              console.log("add news!");
            }}
          >
            Add Route
          </div>
        </div>
        <div className="flex-row w-full flex-wrap grid-cols-4 grid gap-4 mb-6">
          {this.state.data?.map((item) => {
            return (
              <div key={item.id} className="container bg-gray-50 p-2 rounded-md shadow">
                <div className="text-sm">{item.start_date}</div>
                <div className="font-bold">{item.title}</div>
                <div className="text-base">{item.caption}</div>
                {item.is_ticket ? (
                  <div className="bg-blue-500 text-sm text-white w-fit p-2 align-middle rounded mt-2 select-none">
                    Ticket Available
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    forceLogout: (data) => dispatch(forceLogout(data)),
    getEvents: (data) => dispatch(getEvents(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
